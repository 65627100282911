<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">{{ $t('Adding a new working area') }}</div>
      <div v-else class="p-dialog-title">{{ $t('Working area editing') }}</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="area_name" :class="{'p-invalid' : submitted && !areaData.name}" v-model="areaData.name" autocomplete="off"/>
          <label for="area_name">Name<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !areaData.name">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="area_description" v-model="areaData.description" autocomplete="off"/>
          <label for="area_description">Description</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
          <InputNumber id="area_sorting" v-model="areaData.sorting" :min="0" :max="250" showButtons autocomplete="off"/>
          <label for="area_sorting">Sorting</label>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import settings from "@/settings";

export default {
  emits: ['close', 'update-items'],
  name: 'AreaModal',
  props: {
    item: Object,
    visible: Boolean,
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      areaData: {},
      submitted: false
    }
  },
  watch: {
    item() {
      this.areaData = { ...this.item }
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
      }
    },
  },
  methods: {
    async saveItem() {
      this.submitted = true
      if (!this.areaData.name) {
        return false
      }
      this.disableSaveButton = true
      this.dataIsSending = true

      const area = {
        branch_id: this.$store.state.branchData?.id ?? null,
        name: this.areaData.name ? this.areaData.name : null,
        description: this.areaData.description ? this.areaData.description : null,
        sorting: this.areaData.sorting ? this.areaData.sorting : null,
      }

      if (this.areaData.id) {
        area.id = this.areaData.id
        try {
          const { status } = await httpClient.post(`area/update`, area)
          if (status === 200) {
            this.$emit('update-items', this.areaData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`area/create`, area)
          if (status === 201 && data) {
            this.areaData.id = data
            area.id = data
            this.$emit('update-items', this.areaData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>